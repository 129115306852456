import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    promoCartCreation: {
      padding: 20,
    },
  })
);

export const CartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
`;

export const SelectProduct = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px;
`;

export const ProductsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  width: 100%;
  & > * {
    margin-right: 20px;
  }
`;
