import React, { useCallback, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '../../components/Drawer';
import api from '../../services/api';
import ListView from '../../components/Cuts/ListView';
import CategoriesListView from '../../components/Categories/ListView';
import OrdersListView from '../../components/Orders/ListView';
import Cuts from '../../interfaces/cuts';
import Category from '../../interfaces/category';
import Product from '../../interfaces/products';
import AvailableAddresses from '../../components/AvailableAddresses';
import PromoCart from '../../components/PromoCart';
import ProductsList from '../../components/Products';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  })
);

const AdminDashboard: React.FC = () => {
  const classes = useStyles();
  const [activeTitle, setActiveTitle] = useState('Pedidos');
  const [cuts, setCuts] = useState<Cuts[]>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>();

  const handleDrawerClick = useCallback(async (key: string) => {
    switch (key) {
      case 'users':
        break;
      case 'cuts':
        api.get('/cuts').then((res) => {
          setCuts(res.data);
          setActiveTitle('Cortes');
        });
        break;
      case 'categories':
        api.get('/categories').then((res) => {
          setCategories(res.data);
          setActiveTitle('Categorias');
        });
        break;
      case 'products':
        api.get('/cuts').then((res) => {
          setCuts(res.data);
        });
        api.get('/categories').then((res) => {
          setCategories(res.data);
        });
        api.get('/products/all').then((res) => {
          setProducts(res.data);
          setActiveTitle('Produtos');
        });
        break;
      case 'orders':
        setActiveTitle('Pedidos');
        break;
      case 'availableCeps':
        setActiveTitle('Ceps Atendidos');
        break;
      case 'promoCarts':
        setActiveTitle('Kits');
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {activeTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer props={{ handleDrawerClick }} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {activeTitle === 'Cortes' && <ListView cuts={cuts} />}
        {activeTitle === 'Categorias' && (
          <CategoriesListView categories={categories} />
        )}
        {activeTitle === 'Produtos' && (
          <ProductsList data={{ products, categories, cuts }} />
        )}
        {activeTitle === 'Pedidos' && <OrdersListView />}
        {activeTitle === 'Ceps Atendidos' && <AvailableAddresses />}
        {activeTitle === 'Kits' && <PromoCart />}
      </main>
    </div>
  );
};
export default AdminDashboard;
