import styled from 'styled-components';

interface IProps {
  value: number;
  size: number;
}

export const Container = styled.div<IProps>`
  position: relative;
  background: linear-gradient(0deg, #fff, #f9f9f9, #fff);
  /* background: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b); */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    .number h2 {
      color: black;
      font-size: ${(props) => props.size / 2 + 5}px;
      h2 {
        color: black;
      }
    }
    .text {
      color: black;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: ${(props) => props.size * 2}px;
      height: ${(props) => props.size * 2}px;

      h2 {
        color: #777;
        font-weight: 700;
        font-size: ${(props) => props.size / 2}px;
        transition: 0.5s;
        span {
          font-size: ${(props) => props.size / 2}px;
          color: #777;
          transition: 0.5s;
        }
      }
    }

    .text {
      position: relative;
      color: #777;
      margin-top: 20px;
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 1px;
      transition: 0.5s;
    }
  }
`;
