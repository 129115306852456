import { makeStyles, createStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#f0f0f0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const GaugesContainer = styled.div`
  margin: auto;
  display: flex;
  position: relative;
  width: 900px;
  justify-content: space-between;
`;
