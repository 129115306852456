import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

interface DeleteProps {
  open: boolean;
  close: (res: boolean) => void;
  name: string;
}

const DeleteDialog: React.FC<DeleteProps> = (props) => {
  const { open, close, name } = props;

  const handleClose = useCallback(
    (response: boolean) => {
      close(response);
    },
    [close]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja deletar {name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
