import React, { useCallback, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import { ListItemSecondaryAction, IconButton } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import api from '../../../services/api';
import Cuts from '../../../interfaces/cuts';
import useStyles from './styles';

interface ListProps {
  cuts: Cuts[] | undefined;
}

const ListView: React.FC<ListProps> = ({ cuts }) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [cutsList, setCutsList] = useState(cuts);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ): void => {
    setSelectedIndex(index);
  };

  const handleDeleteItem = useCallback(
    (id: string) => {
      api.delete(`/cuts/del/${id}`).then(() => {
        const newList = cutsList?.filter((cut) => cut.id !== id);
        setCutsList(newList);
      });
    },
    [cutsList]
  );

  const [name, setName] = useState('');

  const handleCreateNew = useCallback(async () => {
    if (name) {
      api.post('/cuts', { name }).then((res) => {
        const newList = cutsList?.concat(res.data);
        setCutsList(newList);
        setName('');
      });
    }
  }, [cutsList, name]);

  const inputFields = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      Novo Corte{' '}
      <TextField
        placeholder="nome"
        value={name}
        onChange={(event) => setName(event.target.value)}
        style={{ marginLeft: 15 }}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folder">
        <ListItem>
          <ListItemText primary={inputFields} />

          <ListItemSecondaryAction>
            <IconButton onClick={handleCreateNew}>
              <Add />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {cutsList?.map((cut) => (
          <ListItem
            key={cut.id}
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemText primary={cut.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => handleDeleteItem(cut.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default ListView;
