import React, { useState } from 'react';
import MaterialDrawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import List from '@material-ui/core/List';
import useStyles from './styles';
import { useAuth } from '../../hooks/AuthContext';

interface DrawerProps {
  props: { handleDrawerClick: any };
}

const Drawer: React.FC<DrawerProps> = ({ props }) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const { handleDrawerClick } = props;
  const [active, setActive] = useState('orders');
  return (
    <MaterialDrawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <div className={classes.drawerHeader}>
          <h2>
            <strong className={classes.headerStrong}>SANTA</strong> CARNE
          </h2>
        </div>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <PowerIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>

      <Divider />
      <List>
        <ListItem
          button
          selected={active === 'orders'}
          onClick={() => {
            handleDrawerClick('orders');
            setActive('orders');
          }}
        >
          <ListItemText primary="Pedidos" />
        </ListItem>
        {/* <ListItem
          button
          className={active === 'users' ? classes.active : ''}
          onClick={() => {
            handleDrawerClick('users');
            setActive('users');
          }}
        >
          <ListItemText primary="Clientes" />
        </ListItem> */}
        <ListItem
          button
          selected={active === 'cuts'}
          onClick={() => {
            handleDrawerClick('cuts');
            setActive('cuts');
          }}
        >
          <ListItemText primary="Cortes" />
        </ListItem>
        <ListItem
          button
          selected={active === 'categories'}
          onClick={() => {
            handleDrawerClick('categories');
            setActive('categories');
          }}
        >
          <ListItemText primary="Categorias" />
        </ListItem>
        <ListItem
          button
          selected={active === 'products'}
          onClick={() => {
            handleDrawerClick('products');
            setActive('products');
          }}
        >
          <ListItemText primary="Produtos" />
        </ListItem>
        <ListItem
          button
          selected={active === 'availableCeps'}
          onClick={() => {
            handleDrawerClick('availableCeps');
            setActive('availableCeps');
          }}
        >
          <ListItemText primary="Ceps Atendidos" />
        </ListItem>
        <ListItem
          button
          selected={active === 'promoCarts'}
          onClick={() => {
            handleDrawerClick('promoCarts');
            setActive('promoCarts');
          }}
        >
          <ListItemText primary="Kits" />
        </ListItem>
      </List>
    </MaterialDrawer>
  );
};
export default Drawer;
