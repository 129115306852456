import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';
import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertToRaw } from 'draft-js';
import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import Category from '../../../interfaces/category';
import Cuts from '../../../interfaces/cuts';
import Product from '../../../interfaces/products';

export interface ProductCreation {
  cod?: string;
  name: string;
  image: File | string;
  category: Category;
  cuts?: Cuts[];
  price: number;
  description: string;
  imageurl: string;
}

interface SimpleDialogProps {
  openDialog: boolean;
  onClose: (product: ProductCreation | 'cancel') => void;
  product: Product | undefined;
  categories: Category[];
  cuts?: Cuts[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductDialog: React.FC<SimpleDialogProps> = (props) => {
  const classes = useStyles();
  const { openDialog, onClose, product, categories, cuts } = props;
  const [selectedCuts, setSelectedCuts] = useState<Cuts[] | undefined>();
  const [image, setImage] = useState<File | string>();
  const [cod, setCod] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [category, setCategory] = useState<Category>();
  const [price, setPrice] = useState<number>(0);
  const [description, setDescription] = useState(
    JSON.stringify(convertToRaw(ContentState.createFromText('sem descrição')))
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (product) {
      const text = ContentState.createFromText('sem descrição');

      setImage(product.imageUrl);
      setCod(product.cod || '');
      setName(product.name);
      setCategory(product.category);
      setPrice(product.price);
      setSelectedCuts(product.cuts ? product.cuts : undefined);
      if (product.description) {
        try {
          if (JSON.parse(product.description))
            setDescription(product.description);
        } catch (err) {
          setDescription(JSON.stringify(convertToRaw(text)));
        }
      }
    } else {
      setImage(undefined);
      setCod('');
      setName('');
      setCategory(undefined);
      setPrice(0);
      setSelectedCuts(undefined);
      setDescription(
        JSON.stringify(
          convertToRaw(ContentState.createFromText('sem descrição'))
        )
      );
    }
  }, [product]);

  const handleSelectCutChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const list = event.target.value as string[];
    const listToReturn: Cuts[] = [];
    if (cuts)
      list.forEach((l) => {
        const c = cuts.find((cut) => {
          return cut.name === l;
        });
        if (c) listToReturn.push(c);
      });

    setSelectedCuts(listToReturn.length > 0 ? listToReturn : undefined);
  };

  const handleClose = useCallback(
    (button: ProductCreation | 'cancel') => {
      setSaving(true);
      onClose(button);
      setSaving(false);
    },
    [onClose]
  );

  const save = (data: string): void => {
    setDescription(data);
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={openDialog}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">
        {product ? 'Editar Produto' : 'Criar Novo Produto'}
      </DialogTitle>

      <DialogContent>
        <div className={classes.dialogHeader}>
          <div>
            <label htmlFor="image">
              {image ? (
                <img
                  src={
                    typeof image !== 'string'
                      ? URL.createObjectURL(image)
                      : image
                  }
                  alt={name}
                  style={{ width: 100 }}
                />
              ) : (
                <AddAPhoto style={{ width: 100, height: 100 }} />
              )}

              <input
                id="image"
                style={{ display: 'none' }}
                type="file"
                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                onChange={(event) => {
                  const { files } = event.target;
                  if (files) setImage(files[0]);
                }}
              />
            </label>
          </div>
          <div className={classes.dialogHeaderRight}>
            <TextField
              label="Código"
              defaultValue={cod}
              onChange={(val) => {
                setCod(val.target.value);
              }}
            />
            <TextField
              label="Nome"
              defaultValue={name}
              onChange={(val) => {
                setName(val.target.value);
              }}
            />
            <FormControl className={classes.selectStyle}>
              <InputLabel id="category-select-label">Categoria</InputLabel>
              <Select
                labelId="category-select-label"
                id="demo-simple-select"
                value={category?.id}
                onChange={(val) => {
                  setCategory(
                    categories.find((cat) => cat.id === val.target.value)
                  );
                }}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.selectStyle}>
              <InputLabel id="cuts-select-label">Cortes</InputLabel>
              {cuts && (
                <Select
                  labelId="cuts-select-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={selectedCuts ? selectedCuts.map((s) => s.name) : []}
                  onChange={handleSelectCutChange}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                  MenuProps={MenuProps}
                  style={{ minWidth: 100 }}
                >
                  {cuts.map((c) => (
                    <MenuItem key={c.id} value={c.name}>
                      <Checkbox
                        checked={
                          selectedCuts ? selectedCuts.indexOf(c) > -1 : false
                        }
                      />
                      <ListItemText primary={c.name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>

            <TextField
              label="Preço"
              type="number"
              defaultValue={price || ''}
              inputProps={{ inputProps: { min: 0, step: 0.01 } }}
              onChange={(val) => {
                setPrice(parseFloat(val.target.value));
              }}
            />
          </div>
        </div>
        <MUIRichTextEditor
          label="Type something here..."
          defaultValue={description}
          onChange={(state) => {
            save(JSON.stringify(convertToRaw(state.getCurrentContent())));
          }}
          inlineToolbar
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose('cancel')}>Cancelar</Button>
        <Button
          onClick={() => {
            if (name && image && category && price && description) {
              handleClose(
                {
                  cod: cod === '' ? undefined : cod,
                  name,
                  image,
                  category,
                  cuts: selectedCuts,
                  price,
                  imageurl: product?.imageUrl || '',
                  description,
                } || 'cancel'
              );
            } else handleClose('cancel');
          }}
        >
          {saving ? <CircularProgress /> : <>Salvar</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ProductDialog;
