import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import Category from '../../interfaces/category';
import Cuts from '../../interfaces/cuts';
import Product from '../../interfaces/products';
import api from '../../services/api';
import ProductCard from './Card';
import ProductDialog, { ProductCreation } from './Dialog';
import { Container } from './styles';

interface ListProps {
  data: {
    products: Product[] | undefined;
    categories: Category[];
    cuts?: Cuts[];
  };
}

const ProductsList: React.FC<ListProps> = ({ data }) => {
  const { products, categories, cuts } = data;

  const [selectedFilter, setSelectedFilter] = useState('');
  const [productsList, setProducts] = useState(products);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogProduct, setDialogProduct] = useState<Product>();

  const productsToShow = useMemo((): Product[] | undefined => {
    if (productsList) {
      if (selectedFilter === '') return [...productsList];
      return [
        ...productsList.filter((prod) => prod.category.id === selectedFilter),
      ];
    }
    return undefined;
  }, [productsList, selectedFilter]);

  const xopenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const xdialogProduct = useCallback((prod: Product) => {
    setDialogProduct(prod);
  }, []);

  const handleCloseDialog = useCallback(
    (prod: ProductCreation | 'cancel'): void => {
      setOpenDialog(false);
      if (prod !== 'cancel') {
        const formData = new FormData();
        if (typeof prod.image !== 'string')
          formData.append('image', prod.image);
        else formData.append('imageurl', prod.imageurl);
        formData.append('cod', prod.cod || '');
        formData.append('name', prod.name);
        formData.append('price', JSON.stringify(prod.price));
        formData.append('description', prod.description);
        formData.append(
          'category',
          JSON.stringify({ id: prod.category.id, name: prod.category.name })
        );
        formData.append(
          'cuts',
          prod.cuts ? JSON.stringify(prod.cuts) : JSON.stringify([])
        );

        api.post('/products', formData).then((res) => {
          const thisProduct = res.data as Product;

          const resp = productsList?.find((item) => item.id === thisProduct.id);
          if (resp) {
            const dataee = productsList?.map((item) => {
              if (item.id === thisProduct.id) {
                return thisProduct;
              }
              return item;
            });
            setProducts([]);
            setProducts(dataee || []);
          } else
            setProducts((prev) =>
              prev ? [...prev, thisProduct] : [thisProduct]
            );

          setOpenDialog(false);
          setDialogProduct(undefined);
        });
      } else {
        setDialogProduct(undefined);
      }
    },
    [productsList]
  );

  const handleDeleteItem = useCallback(
    (id: string) => {
      api.delete(`/products/del/${id}`).then(() => {
        const newList = productsList?.filter((prod) => prod.id !== id);
        setProducts(newList);
      });
    },
    [productsList]
  );

  return (
    <Container>
      <ProductDialog
        openDialog={openDialog}
        onClose={handleCloseDialog}
        product={dialogProduct}
        categories={categories}
        cuts={cuts}
      />
      <div>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-outlined-label">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FilterList /> Filtrar
            </div>
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedFilter}
            onChange={(res) => {
              setSelectedFilter(res.target.value as string);
            }}
            label="Filtrar"
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          type="button"
          style={{
            marginLeft: 18,
            background: 'green',
            padding: 15,
            borderRadius: 5,
            color: 'white',
          }}
          onClick={() => {
            api.patch('products/update_prices').then((res) => {
              setProducts(res.data);
            });
          }}
        >
          Atualizar preços
        </Button>
        <Button
          type="button"
          style={{
            marginLeft: 18,
            background: 'blue',
            padding: 15,
            borderRadius: 5,
            color: 'white',
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Adicionar Novo
        </Button>
      </div>
      <div className="grid-products">
        {productsToShow?.map((item) => (
          <ProductCard
            item={item}
            key={item.id}
            OpenDialog={xopenDialog}
            DialogProduct={xdialogProduct}
            onDelete={handleDeleteItem}
          />
        ))}
      </div>
    </Container>
  );
};

export default ProductsList;
