import Intl from 'intl';
import 'intl/locale-data/jsonp/pt-BR';

const formatValue = (value: number | string): string => {
  let treatedValue = value;
  if (typeof value === 'string') treatedValue = parseFloat(value);

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(treatedValue as number);
};

export default formatValue;
