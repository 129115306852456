import React, { useCallback, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import api from '../../../services/api';
import Category from '../../../interfaces/category';
import useStyles from './styles';

interface ListProps {
  categories: Category[] | undefined;
}

const ListView: React.FC<ListProps> = ({ categories }) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState<string>();
  const [categoriesList, setCategories] = useState(categories);
  const [type, setType] = useState<'weight' | 'itens'>('weight');
  const [name, setName] = useState('');
  const [image, setImage] = useState<File>();

  const handleListItemClick = (id: string): void => {
    setSelectedIndex(id);
    const selectedCategory = categoriesList?.find((cat) => cat.id === id);
    setType(selectedCategory?.type || 'weight');
    setName(selectedCategory?.name || '');
  };

  const handleDeleteItem = useCallback(
    (id: string) => {
      api.delete(`/categories/del/${id}`).then(() => {
        const newList = categoriesList?.filter(
          (category) => category.id !== id
        );
        setCategories(newList);
      });
    },
    [categoriesList]
  );

  const handleCreateNew = useCallback(async () => {
    if (name && image) {
      const data = new FormData();
      data.append('image', image);
      data.append('name', name);
      data.append('type', type);
      api.post('/categories', data).then((res) => {
        if (categoriesList?.find((cat) => cat.id === res.data.id)) {
          setCategories(
            categoriesList.map((cat) => {
              if (cat.id === res.data.id) return res.data;
              return cat;
            })
          );
        } else {
          const newList = categoriesList?.concat(res.data);
          setCategories(newList);
        }
        setName('');
      });
    }
  }, [name, image, type, categoriesList]);

  const inputFields = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      Nova Categoria{' '}
      <TextField
        placeholder="nome"
        value={name}
        onChange={(event) => setName(event.target.value)}
        style={{ margin: '0 15px' }}
      />
      <FormGroup>
        <FormLabel component="legend">Tipo</FormLabel>
        <RadioGroup
          aria-label="type"
          value={type}
          onChange={(event) => {
            setType(event.target.value as 'weight' | 'itens');
          }}
        >
          <FormControlLabel value="weight" control={<Radio />} label="Peso" />
          <FormControlLabel value="itens" control={<Radio />} label="Ítens" />
        </RadioGroup>
      </FormGroup>
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg,image/jpg"
        onChange={(event) => {
          const { files } = event.target;
          if (files) setImage(files[0]);
        }}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="secondary mailbox folder">
        <ListItem>
          <ListItemText primary={inputFields} />

          <ListItemSecondaryAction>
            <IconButton onClick={handleCreateNew}>
              <Add />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {categoriesList?.map((category) => (
          <ListItem
            key={category.id}
            button
            selected={selectedIndex === category.id}
            onClick={() => handleListItemClick(category.id)}
          >
            <ListItemAvatar>
              <img
                src={category.imageUrl}
                alt={category.name}
                style={{ width: 50, marginRight: 20 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${category.name} - ${
                category.type === 'itens' ? 'itens' : 'peso'
              }`}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => handleDeleteItem(category.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default ListView;
