import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  TableContainer,
  TableCell,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableHead,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  TextFieldProps,
  Input,
  Dialog,
  DialogTitle,
  DialogProps,
  InputAdornment,
  Grid,
  DialogActions,
} from '@material-ui/core';
import { Delete, Edit, Search, Check, Add, Save } from '@material-ui/icons';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import api from '../../services/api';
import AvailableCeps from '../../interfaces/availableceps';
import Upload from '../Upload';
import { useToast } from '../../hooks/ToastContext';

interface IDictionary<TValue> {
  [id: string]: TValue;
}

interface ImportProps {
  open: boolean;
  onClose: (data: AvailableCeps[] | undefined) => void;
}

const AvailableAddresses: React.FC = () => {
  const [availableCeps, setAvailableCeps] = useState<AvailableCeps[]>([]);
  const [filteredAvailableCeps, setFilteredAvailableCeps] = useState<
    AvailableCeps[]
  >();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editing, setEditing] = useState('');
  const [newFreteValue, setNewFreteValue] = useState('');
  const [generalFrete, setGeneralFrete] = useState(0);
  const [generalFreteChanged, setGeneralFreteChanged] = useState(false);
  const [waterMarker, setWaterMarker] = useState(1000);
  const [waterMarkerChanged, setWaterMarkerChanged] = useState(false);
  const { addToast } = useToast();
  const cep = useRef<TextFieldProps>(null);
  const state = useRef<TextFieldProps>(null);
  const city = useRef<TextFieldProps>(null);
  const bairro = useRef<TextFieldProps>(null);
  const street = useRef<TextFieldProps>(null);
  const frete = useRef<TextFieldProps>(null);

  useEffect(() => {
    const axiosOne = api.get('/available-cep/all');
    const axiosTwo = api.get('frete');
    const axiosThree = api.get('freteZeroValue');

    axios
      .all([axiosOne, axiosTwo, axiosThree])
      .then(
        axios.spread((...responses) => {
          setAvailableCeps(responses[0].data);
          setGeneralFrete(Number(responses[1].data));
          setWaterMarker(Number(responses[2].data));
        })
      )
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro no carregamento de dados',
          description: `Não conseguimos carregar seus dados corretamente. Tente novamente.\n${err}`,
        });
      });
  }, [addToast]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = useCallback(
    (val) => {
      if (val === '') {
        setFilteredAvailableCeps(undefined);
      }
      const filter = availableCeps.filter((item) =>
        (Object.keys(item) as Array<keyof AvailableCeps>).some((k) => {
          type Dict = { [k: string]: string };
          return (
            item[k] !== null &&
            item[k]?.toString().toLowerCase().includes(val.toLowerCase())
          );
        })
      );
      setFilteredAvailableCeps(filter);
    },
    [availableCeps]
  );

  const isEditing = (id: string): boolean => editing === id;
  const saveNewPriceValue = useCallback(
    (id: string, val: string) => {
      const upable = availableCeps.find((item) => item.id === id);
      api
        .patch(`/available-cep/update/${id}`, {
          data: {
            ...upable,
            deliveryPrice: val,
          },
        })
        .then(() => {
          setAvailableCeps(
            availableCeps.map((item) => {
              if (item.id === id) {
                return { ...item, deliveryPrice: val };
              }
              return item;
            })
          );
          setFilteredAvailableCeps(
            filteredAvailableCeps?.map((item) => {
              if (item.id === id) {
                return { ...item, deliveryPrice: val };
              }
              return item;
            })
          );
        });
    },
    [availableCeps, filteredAvailableCeps]
  );
  const handleDelete = useCallback(
    (id: string) => {
      const ceps = availableCeps.filter((item) => item.id !== id);
      api.delete(`/available-cep/del/${id}`).then(() => {
        setAvailableCeps(ceps);
        setFilteredAvailableCeps(
          filteredAvailableCeps?.filter((item) => item.id !== id)
        );
      });
    },
    [availableCeps, filteredAvailableCeps]
  );

  const handleFormSubmition = useCallback(() => {
    const av = {
      cep: cep.current?.value,
      street: street.current?.value,
      deliveryPrice: frete.current?.value,
      bairro: bairro.current?.value,
      city: city.current?.value,
      state: state.current?.value,
    };
    api.post('/available-cep/add', av).then((res) => {
      if (
        cep.current &&
        street.current &&
        frete.current &&
        bairro.current &&
        city.current &&
        state.current
      ) {
        cep.current.value = '';
        street.current.value = '';
        frete.current.value = '';
        bairro.current.value = '';
        city.current.value = '';
        state.current.value = '';
      }

      setAvailableCeps([res.data, ...availableCeps]);
      if (filteredAvailableCeps)
        setFilteredAvailableCeps([res.data, ...filteredAvailableCeps]);
    });
  }, [availableCeps, filteredAvailableCeps]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <ImportDialog
        open={open}
        onClose={(data: AvailableCeps[] | undefined) => {
          if (data) {
            setAvailableCeps([...data, ...availableCeps]);
            if (filteredAvailableCeps)
              setFilteredAvailableCeps([...data, ...filteredAvailableCeps]);
          }
          setOpen(false);
        }}
      />
      <div>
        <TextField
          label="Frete"
          onChange={(event) => {
            setGeneralFreteChanged(true);
            setGeneralFrete(Number(event.target.value));
          }}
          value={generalFrete}
        />
        {generalFreteChanged && (
          <button
            type="button"
            onClick={() => {
              api
                .post('frete', { frete: generalFrete })
                .then(() => {
                  setGeneralFreteChanged(false);
                })
                .catch((err) => {
                  addToast({
                    type: 'error',
                    title: 'Erro ao enviar dados',
                    description: `Não conseguimos salvar o novo valor de frete. Tente novamente.\n${err}`,
                  });
                });
            }}
          >
            <Save />
          </button>
        )}
      </div>
      <div>
        <TextField
          label="Linha D'Água"
          onChange={(event) => {
            setWaterMarkerChanged(true);
            setWaterMarker(Number(event.target.value));
          }}
          value={waterMarker}
        />
        {waterMarkerChanged && (
          <button
            type="button"
            onClick={() => {
              api
                .post('freteZeroValue', { frete: waterMarker })
                .then(() => {
                  setWaterMarkerChanged(false);
                })
                .catch((err) => {
                  addToast({
                    type: 'error',
                    title: 'Erro ao enviar dados',
                    description: `Não conseguimos salvar o novo valor de frete. Tente novamente.\n${err}`,
                  });
                });
            }}
          >
            <Save />
          </button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', margin: 15 }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <Search />
            </Grid>
            <Grid item>
              <TextField
                id="input-with-icon-grid"
                label="Procurar"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <Add />
            Importar CSV
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Ação</TableCell>
              <TableCell align="center">CEP</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Cidade</TableCell>
              <TableCell align="center">Bairro</TableCell>
              <TableCell align="left">Rua</TableCell>
              {/* <TableCell align="center">Frete</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Button onClick={handleFormSubmition}>
                  <Add />
                </Button>
              </TableCell>
              <TableCell>
                <TextField inputRef={cep} label="CEP" />
              </TableCell>
              <TableCell>
                <TextField inputRef={state} label="Estado" />
              </TableCell>
              <TableCell>
                <TextField inputRef={city} label="Cidade" />
              </TableCell>
              <TableCell>
                <TextField inputRef={bairro} label="Bairro" />
              </TableCell>
              <TableCell>
                <TextField inputRef={street} label="Rua" />
              </TableCell>
              {/* <TableCell>
                <TextField inputRef={frete} label="Frete" type="number" />
              </TableCell> */}
            </TableRow>
            {(rowsPerPage > 0
              ? (filteredAvailableCeps || availableCeps)
                  .slice()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredAvailableCeps || availableCeps
            ).map((row) => {
              const isEditingBool = isEditing(row.id);
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Button>
                      <Delete
                        onClick={() => {
                          handleDelete(row.id);
                        }}
                      />
                    </Button>
                    <Button
                      onClick={() => {
                        if (!isEditingBool) setEditing(row.id);
                        else {
                          saveNewPriceValue(row.id, newFreteValue);
                          setEditing('');
                        }
                      }}
                    >
                      {isEditingBool ? <Check /> : <Edit />}
                    </Button>
                  </TableCell>
                  <TableCell align="center">{row.cep}</TableCell>
                  <TableCell align="center">{row.state}</TableCell>
                  <TableCell align="center">{row.city}</TableCell>
                  <TableCell align="center">{row.bairro}</TableCell>
                  <TableCell align="left">{row.street}</TableCell>
                  {/* <TableCell align="center">
                    {isEditingBool ? (
                      <TextField
                        defaultValue={row.deliveryPrice}
                        type="number"
                        onChange={(e) => {
                          setNewFreteValue(e.target.value);
                        }}
                      />
                    ) : (
                      row.deliveryPrice
                    )}
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                colSpan={3}
                count={(filteredAvailableCeps || availableCeps).length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}
const ImportDialog: React.FC<ImportProps> = (props) => {
  const { onClose, open } = props;
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  async function submitFile(files: File[]): Promise<void> {
    setUploadedFiles(
      files.map(
        (file): FileProps => {
          return {
            name: file.name,
            file,
            readableSize: String(file.size),
          };
        }
      )
    );
  }
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={async () => {
        const data = new FormData();

        uploadedFiles.map((file) => data.append('file', file.file));

        await api
          .post('/available-cep/import', data)
          .then((res) => {
            onClose(res.data);
          })
          .catch(() => {
            onClose(undefined);
          });
      }}
    >
      <DialogTitle id="simple-dialog-title">
        Importar CEPs - Formato CSV
      </DialogTitle>
      {uploadedFiles.length > 0 ? (
        'Carregado'
      ) : (
        <Upload onUpload={submitFile} />
      )}
    </Dialog>
  );
};

export default AvailableAddresses;
