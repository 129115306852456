import React from 'react';
import { Container } from './styles';
import './circle.css';

interface IProps {
  value: string;
  title: string;
}

const GaugeCard: React.FC<IProps> = (props) => {
  const { value, title } = props;

  return (
    <Container value={parseFloat(value)} size={70}>
      <div className="box">
        <div className={`c100 p${Math.round(parseFloat(value))}`}>
          <span>{value}%</span>
          <div className="slice">
            <div className="bar" />
            <div className="fill" />
          </div>
        </div>
        <h2 className="text">{title}</h2>
      </div>
    </Container>
  );
};

export default GaugeCard;
