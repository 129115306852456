import React from 'react';
import { Switch } from 'react-router-dom';

// import Home from '../pages/Home';
import AdminDashboard from '../pages/AdminDashboard';
import SignIn from '../pages/SignIn';
import Route from './Route';
import ResetPassword from '../pages/ResetPassword';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={AdminDashboard} isPrivate />

    <Route path="/signin" component={SignIn} />
    <Route path="/reset-password" component={ResetPassword} />
    {/* <Route path="/admin" component={AdminDashboard} isPrivate /> */}
  </Switch>
);
export default Routes;
