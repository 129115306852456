import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background: white;
  padding: 1.5rem;
  -webkit-box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.25);

  transition: all 0.3s ease-out;
  &:hover {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  #product-imgs-container {
    position: relative;
    text-align: center;
  }

  #product-img {
    width: 250px;
    z-index: 1;
  }

  #product-category {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    width: 75px;
  }
  .available {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #trashCan {
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
  }
`;
