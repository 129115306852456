import { createGlobalStyle } from 'styled-components';
import { createMuiTheme, colors } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      dark: colors.indigo[900],
      main: colors.indigo[500],
      light: colors.indigo[100],
    },
    secondary: {
      contrastText: '#fff',
      dark: colors.blue[900],
      main: colors.blue.A400,
      light: colors.blue.A400,
    },
    success: {
      contrastText: '#fff',
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400],
    },
    info: {
      contrastText: '#fff',
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    warning: {
      contrastText: '#fff',
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400],
    },
    error: {
      contrastText: '#fff',
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    background: {
      default: '#F4F6F8',
      paper: '#fff',
    },
    divider: colors.grey[200],
  },
  // overrides: {
  //   MuiTableCell: {
  //     head: {
  //       backgroundColor: '#8b1515',
  //       color: '#fff',
  //     },
  //     stickyHeader: {
  //       backgroundColor: '#8b1515',
  //       color: '#fff',
  //     },
  //     // body: {
  //     //   backgroundColor: green[600],
  //     // },
  //   },
  //   MuiListItem: {
  //     root: {
  //       '&$selected': {
  //         backgroundColor: '#8b1515',
  //         color: '#fff',
  //       },
  //       '&:hover': {
  //         backgroundColor: '#b71c1c',
  //         color: '#fff',
  //       },
  //     },
  //   },
  // },
});

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    outline:0;
  }
  body{
    -webkit-font-smoothing:antialised;
  }

  button {
    cursor: pointer;
  }
`;
