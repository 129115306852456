import React, { useState, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { parseISO, isSameMonth } from 'date-fns';
import {
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Switch,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Print as PrintIcon } from '@material-ui/icons';
import { setInterval } from 'timers';
import api from '../../../services/api';
import './print.css';
import { useStyles, GaugesContainer } from './styles';
import Order from '../../../interfaces/orders';
import { CartItem } from '../../../interfaces/cart';
import { useAuth } from '../../../hooks/AuthContext';
import GaugeCard from '../../StatisticalComponents/GaugeCard';
import NumberCard from '../../StatisticalComponents/NumberCard';
import formatValue from '../../../utils/formatValue';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#307bbb',
    '&$checked': {
      color: '#307bbb',
    },
    '&$checked + $track': {
      backgroundColor: '#307bbb',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ListView: React.FC = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState<Order[]>([]);
  const history = useHistory();
  const { signOut } = useAuth();

  useEffect(() => {
    api
      .get('/orders')
      .then(({ data }: { data: Order[] }) => {
        const t = data.sort(
          (a, b) =>
            parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime()
        );
        setOrders(t || []);
      })
      .catch(() => {
        signOut();
      });
    setInterval(() => {
      api
        .get('/orders')
        .then(({ data }: { data: Order[] }) => {
          const t = data.sort(
            (a, b) =>
              parseISO(b.created_at).getTime() -
              parseISO(a.created_at).getTime()
          );
          setOrders(t || []);
        })
        .catch(() => {
          signOut();
        });
    }, 1000 * 60);
  }, [history, signOut]);

  const [monthly, setMonthly] = useState(true);
  const completedOrders = useMemo(() => {
    let completed;
    completed = orders.filter((order) => order.finished === true);
    if (monthly) {
      completed = completed.filter((order) =>
        isSameMonth(new Date(), new Date(order.created_at))
      );
    }
    return ((completed.length * 100) / orders.length).toFixed(1);
  }, [monthly, orders]);

  const tradedVolume = useMemo(() => {
    let filtered = orders;
    if (monthly)
      filtered = orders.filter((order) =>
        isSameMonth(new Date(), new Date(order.created_at))
      );
    return filtered.reduce((acc: number, ord): number => {
      return acc + parseFloat(ord.frete) + parseFloat(ord.subtotal);
    }, 0);
  }, [monthly, orders]);

  async function printDiv(divName: string, data: Order): Promise<void> {
    // const itens: CartItem[] = await api
    //   .get(`/cart/cart/${data.cart.id}`)
    //   .then((res) => {
    //     return res.data || [];
    //   })
    //   .catch(() => []);
    const itens = data.cart.cartItems;
    console.log('LOG', itens, data);
    const ToPrint: React.ReactElement = (
      <div className="print" id="to-print" style={{ fontSize: '1.2rem' }}>
        <p style={{ padding: 2, margin: 0 }}>
          Cliente: <strong>{data.user.name}</strong>
        </p>
        <p style={{ padding: 2, margin: 0 }}>
          Endereço:{' '}
          <strong>
            {data.choosenAddress?.street},{data.choosenAddress?.number}
          </strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          Complemento: <strong>{data.choosenAddress?.more}</strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          Telefone: <strong>{data.choosenAddress.phone}</strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          Pedido: <strong>{data.id}</strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          Data:{' '}
          <strong>{new Date(data.created_at).toLocaleString('pt-BR')}</strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          CPF: <strong>{data?.cpf}</strong>
        </p>
        <p style={{ padding: 2, margin: 0, fontSize: '0.9rem' }}>
          Pagamento:{' '}
          <strong>
            {data.paymentMethod === 'card' ? 'Cartão' : 'Dinheiro'}
          </strong>
        </p>
        <h4 style={{ padding: 2, margin: 0 }}>
          Total de Produtos: <strong>{formatValue(data.subtotal)}</strong>
        </h4>
        <h4 style={{ padding: 2, margin: 0 }}>
          Frete: <strong>{formatValue(data.frete)}</strong>
        </h4>
        {data.paymentMethod === 'money' && (
          <p>
            Troco: <strong>{formatValue(data.change)}</strong>
          </p>
        )}
        {data.cart.title && (
          <h4>
            Kit: <strong>{data.cart.title}</strong>
          </h4>
        )}
        <h4>PRODUTOS:</h4>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {itens.map((item) => {
            console.log(item);
            return (
              <li
                key={item.id}
                style={{ borderBottom: '1px solid #c5c5c5', padding: 5 }}
              >
                {item.product && <div>{item.product.name}</div>}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {item.cut && <div>{item.cut.name}</div>}
                  <div>
                    {item.quantity}
                    {item.product.category.type === 'itens' ? 'und' : 'Kg'}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
    const tela_impressao = window.open('about:blank');
    tela_impressao?.document.write(ReactDOMServer.renderToString(ToPrint));
    tela_impressao?.window.print();
    tela_impressao?.window.close();
  }

  return (
    <div className={`${classes.root} no-print`}>
      <div style={{ margin: 20 }}>
        <a
          href={`data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(orders)
          )}`}
          download="orders.json"
        >
          Export JSON
        </a>
        <div>
          <span>anual</span>
          <PurpleSwitch
            checked={monthly}
            onChange={() => {
              setMonthly(!monthly);
            }}
          />
          <span>mensal</span>
        </div>
        <GaugesContainer>
          <GaugeCard value={completedOrders} title="Finalizadas" />
          <NumberCard value={tradedVolume} title="Volume" />
          <NumberCard value={orders.length} title="Ordens" />
        </GaugesContainer>
      </div>
      <TableContainer component={Paper} className="no-print">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Carrinho Promocional</TableCell>
              <TableCell align="center">Nota Fiscal</TableCell>
              <TableCell align="center">Pagamento</TableCell>
              <TableCell align="center">Troco</TableCell>
              <TableCell align="center">Subtotal</TableCell>
              <TableCell align="center">Frete</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">Manhã</TableCell>
              <TableCell align="center">Produção</TableCell>
              <TableCell align="center">Delivery</TableCell>
              <TableCell align="center">Fim</TableCell>
              <TableCell align="center">Print</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => {
              const express =
                row.deliveryPeriod === 'afternoon' &&
                parseFloat(row.frete) > 14;
              return (
                <TableRow
                  key={row.id}
                  hover
                  style={{
                    backgroundColor: express
                      ? 'rgba(23, 175, 209,0.4)'
                      : 'white',
                  }}
                >
                  <TableCell align="center">
                    {new Date(row.created_at).toLocaleString('pt-BR')}
                  </TableCell>
                  <TableCell align="center">{row.user.name}</TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.isPromoCart} readOnly />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.notaFiscal} readOnly />
                  </TableCell>
                  <TableCell align="center">{row.paymentMethod}</TableCell>
                  <TableCell align="center">{row.change}</TableCell>
                  <TableCell align="center">
                    {formatValue(row.subtotal)}
                  </TableCell>
                  <TableCell align="center">{formatValue(row.frete)}</TableCell>
                  <TableCell align="center">
                    {formatValue(
                      parseFloat(row.subtotal) + parseFloat(row.frete)
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.deliveryPeriod === 'morning'}
                      readOnly
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.inProduction}
                      onClick={() => {
                        if (!row.inProduction)
                          api
                            .patch('/orders/update', {
                              id: row.id,
                              inProduction: true,
                              inDelivery: row.inDelivery,
                              finished: row.finished,
                            })
                            .then(() => {
                              setOrders(
                                orders.map((o) => {
                                  if (o.id === row.id) {
                                    return { ...o, inProduction: true };
                                  }
                                  return o;
                                })
                              );
                            });
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.inDelivery}
                      onClick={() => {
                        if (!row.inDelivery)
                          api
                            .patch('/orders/update', {
                              id: row.id,
                              inProduction: true,
                              inDelivery: true,
                              finished: row.finished,
                            })
                            .then(() => {
                              setOrders(
                                orders.map((o) => {
                                  if (o.id === row.id) {
                                    return {
                                      ...o,
                                      inProduction: true,
                                      inDelivery: true,
                                    };
                                  }
                                  return o;
                                })
                              );
                            });
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.finished}
                      onClick={() => {
                        if (!row.finished)
                          api
                            .patch('/orders/update', {
                              id: row.id,
                              inProduction: true,
                              inDelivery: true,
                              finished: true,
                            })
                            .then(() => {
                              setOrders(
                                orders.map((o) => {
                                  if (o.id === row.id) {
                                    return {
                                      ...o,
                                      inProduction: true,
                                      inDelivery: true,
                                      finished: true,
                                    };
                                  }
                                  return o;
                                })
                              );
                            });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={async () => {
                        printDiv('to-print', row);
                      }}
                    >
                      <PrintIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListView;
