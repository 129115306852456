/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CircularProgress, Switch } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import Product from '../../../interfaces/products';
import api from '../../../services/api';
import { Container } from './styles';

const ProductCard: React.FC<{
  item: Product;
  DialogProduct: (prod: Product) => void;
  OpenDialog: () => void;
  onDelete: (id: string) => void;
}> = ({ item, OpenDialog, DialogProduct, onDelete }) => {
  const [product, setProduct] = useState(item);
  const [savingAvailable, setSavingAvailable] = useState(false);

  const handleChangeAvailability = useCallback(() => {
    setSavingAvailable(true);
    api
      .patch(`/products/${product.id}/available`)
      .then(() => {
        setProduct((prev) => ({ ...prev, available: !prev.available }));
      })
      .finally(() => {
        setSavingAvailable(false);
      });
  }, [product.id]);

  return (
    <Container>
      <Delete
        id="trashCan"
        onClick={() => {
          onDelete(product.id);
        }}
      />
      <h1>{product.name}</h1>
      <div id="product-imgs-container">
        <img
          id="product-img"
          src={product.imageUrl}
          alt="img"
          onClick={() => {
            DialogProduct(item);
            OpenDialog();
          }}
          onKeyDown={() => {
            DialogProduct(item);
            OpenDialog();
          }}
        />
        <img id="product-category" src={product.category.imageUrl} alt="cat" />
      </div>

      <h2>
        {product.price.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </h2>
      <div className="available">
        <h2>Disponível</h2>
        {savingAvailable ? (
          <CircularProgress />
        ) : (
          <Switch
            checked={product.available}
            onClick={handleChangeAvailability}
          />
        )}
      </div>
    </Container>
  );
};
export default ProductCard;
