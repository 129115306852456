import { makeStyles, createStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },

    toolbar: theme.mixins.toolbar,

    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },

    headerStrong: {
      color: '#fc0000',
    },

    active: {},
  })
);

export default useStyles;
