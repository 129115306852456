import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  ListItemIcon,
  Checkbox,
  Select,
  MenuItem,
  Collapse,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { Add, Delete, Edit, AddAPhoto } from '@material-ui/icons';
import { uuid } from 'uuidv4';
import { Cart, Cut } from '../../interfaces/cart';
import api from '../../services/api';
import { useAuth } from '../../hooks/AuthContext';
import Category from '../../interfaces/category';
import {
  CartsContainer,
  useStyles,
  SelectProduct,
  ProductsList,
} from './styles';
import Product from '../../interfaces/products';
import DeleteDialog from '../DeleteDialog';

interface CartItemProp {
  id: string;
  product: Product;
  quantity: number;
  cut?: Cut;
}

const PromoCart: React.FC = () => {
  const [promoCarts, setPromoCarts] = useState<Cart[]>([]);
  const { signOut } = useAuth();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [addedItens, setAddedItens] = useState<CartItemProp[]>([]);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState<File | string>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');
  const [savingAvailable, setSavingAvailable] = useState(false);

  useEffect(() => {
    api
      .get('/cart/promo-cart')
      .then((res) => {
        setPromoCarts(res.data);
      })
      .catch(() => {
        signOut();
      });
  }, [signOut]);

  const sortedCarts = useMemo(() => {
    return promoCarts.sort((a, b) => {
      if (a.title && b.title) return a.title > b.title ? 1 : -1;
      return -1;
    });
  }, [promoCarts]);

  const handleCloseCartDialog = useCallback(
    (cartItem: CartItemProp | 'cancel') => {
      if (cartItem === 'cancel') {
        setOpenDialog(false);
      } else {
        setAddedItens([...addedItens, cartItem]);
        setOpenDialog(false);
      }
    },
    [addedItens]
  );

  const handleAddItem = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleSaveNewCart = useCallback(() => {
    if (title !== '' && price !== 0 && addedItens.length > 0) {
      const formData = new FormData();
      if (image && typeof image !== 'string') formData.append('image', image);
      formData.append('title', title);
      formData.append('cartItens', JSON.stringify(addedItens));
      formData.append('cartTotal', JSON.stringify(price));
      api
        .post('/cart/promo-cart', formData)
        .then((res) => {
          const list = promoCarts.find((item) => item.id === res.data.id);
          if (list)
            setPromoCarts(
              promoCarts.map((item) => {
                if (item.id === res.data.id) return res.data;
                return item;
              })
            );
          else setPromoCarts([...promoCarts, res.data]);
        })
        .finally(() => {
          setTitle('');
          setPrice(0);
          setAddedItens([]);
        });
    }
  }, [addedItens, image, price, promoCarts, title]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      api.delete(`/cart/promo-cart/del/${id}`).then(() => {
        const newList = promoCarts?.filter((item) => item.id !== id);
        setPromoCarts(newList);
      });
    },
    [promoCarts]
  );

  const handleCloseDeleteDialog = useCallback(
    (res: boolean) => {
      if (res) {
        handleDeleteItem(itemToDelete);
      }
      setOpenDeleteDialog(false);
    },
    [handleDeleteItem, itemToDelete]
  );

  const handleChangeAvailability = useCallback(
    (cart: Cart) => {
      setSavingAvailable(true);
      api
        .patch(`/cart/promo-cart/${cart.id}/update`, {
          ...cart,
          available: !cart.available,
        })
        .then((res) => {
          setPromoCarts(
            promoCarts.map((item) => {
              if (item.id === cart.id)
                return { ...item, available: res.data.available };
              return item;
            })
          );
        })
        .finally(() => {
          setSavingAvailable(false);
        });
    },
    [promoCarts]
  );

  return (
    <div>
      <DeleteDialog
        open={openDeleteDialog}
        close={handleCloseDeleteDialog}
        name="esta promoção"
      />
      <CartDialog openDialog={openDialog} onClose={handleCloseCartDialog} />
      <CartsContainer>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Título</TableCell>
                <TableCell align="center">Banner</TableCell>
                <TableCell align="center">Preço</TableCell>
                <TableCell align="center">Disponível</TableCell>
                <TableCell align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCarts.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">
                    {row.imageUrl && (
                      <img
                        src={row.imageUrl}
                        alt={row.title}
                        style={{ height: 50, marginRight: 20 }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">{row.cartTotal}</TableCell>
                  <TableCell align="center">
                    {savingAvailable ? (
                      <CircularProgress />
                    ) : (
                      <Checkbox
                        checked={row.available}
                        onChange={() => {
                          handleChangeAvailability(row);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button>
                      <Delete
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setItemToDelete(row.id);
                        }}
                      />
                    </Button>
                    <Button
                      onClick={() => {
                        setTitle(row.title || '');
                        setPrice(row.cartTotal);
                        setAddedItens(
                          row.cartItens?.map((item) => {
                            return {
                              id: uuid(),
                              product: item.product,
                              quantity: item.quantity,
                              cut: item.cut,
                            };
                          }) || []
                        );
                      }}
                    >
                      <Edit />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper elevation={3} className={classes.promoCartCreation}>
          <Typography>Criar nova Promoção</Typography>
          <label htmlFor="image">
            {image ? (
              <img
                src={
                  typeof image !== 'string' ? URL.createObjectURL(image) : image
                }
                alt={title}
                style={{ width: 100 }}
              />
            ) : (
              <AddAPhoto style={{ width: 100, height: 100 }} />
            )}

            <input
              id="image"
              style={{ display: 'none' }}
              type="file"
              accept="image/x-png,image/gif,image/jpeg,image/jpg"
              onChange={(event) => {
                const { files } = event.target;
                if (files) setImage(files[0]);
              }}
            />
          </label>
          <TextField
            label="Título"
            value={title}
            onChange={(val) => {
              setTitle(val.target.value);
            }}
          />
          <List>
            <ListItem>
              <ListItemText>Adicionar Produto</ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={handleAddItem}>
                  <Add />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {addedItens.map((item) => (
              <ListItem key={item.id}>
                <ProductsList>
                  <Typography>{item.product.name}</Typography>
                  {item.cut && <Typography>{item.cut.name}</Typography>}
                  <Typography>{item.quantity}</Typography>
                  <Button
                    onClick={() => {
                      setAddedItens(
                        addedItens.filter((it) => it.id !== item.id)
                      );
                    }}
                  >
                    <Delete />
                  </Button>
                </ProductsList>
              </ListItem>
            ))}
          </List>
          <TextField
            label="Preço"
            type="number"
            value={price}
            onChange={(val) => {
              setPrice(parseFloat(val.target.value));
            }}
            inputProps={{ inputProps: { min: 0, step: 0.01 } }}
          />
          <Button onClick={handleSaveNewCart}>Salvar</Button>
        </Paper>
      </CartsContainer>
    </div>
  );
};

interface CartDialogProps {
  openDialog: boolean;
  onClose: (cartItem: CartItemProp | 'cancel') => void;
}

const CartDialog: React.FC<CartDialogProps> = (props) => {
  const { openDialog, onClose } = props;
  const [categories, setCategories] = useState<Category[]>();
  const [selectedCategory, setSelectedCategory] = useState<Product[]>([]);
  const [product, setProduct] = useState<Product>();
  const [cut, setCut] = useState<Cut>();
  const [quantity, setQuantity] = useState(0);
  const [checked, setChecked] = React.useState<string>();
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    api.get('/categories').then((response) => {
      setCategories(response.data);
      if (response.data.lenght > 0) {
        const item = response.data[0];
        setSelectedCategory(item.products);
      }
    });
  }, []);

  const handleToggle = (id: string) => () => {
    setChecked(id);
    setOpenSelect(!openSelect);
    setProduct(selectedCategory.find((prod) => prod.id === id));
  };

  const handleCutSelection = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const cutVariable = product?.cuts
      ? product.cuts.find((item) => item.id === (event.target.value as string))
      : undefined;
    if (cutVariable?.id && cutVariable?.name)
      setCut({ id: cutVariable.id, name: cutVariable.name });
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      open={openDialog}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">Criar novo item</DialogTitle>
      <DialogContent>
        <GridList cols={3.5}>
          {categories?.map((tile) => (
            <GridListTile
              key={tile.id}
              onClick={() => {
                setSelectedCategory(tile.products);
              }}
            >
              <img src={tile.imageUrl} alt={tile.name} />
              <GridListTileBar title={tile.name} />
            </GridListTile>
          ))}
        </GridList>
        <List>
          <ListSubheader>Produtos</ListSubheader>
          {selectedCategory.map((item) => {
            const labelId = `checkbox-list-label-${item.id}`;
            return (
              <ListItem button onClick={handleToggle(item.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked === item.id}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </ListItem>
            );
          })}
          <Collapse in={openSelect} timeout="auto" unmountOnExit>
            <SelectProduct>
              {product?.cuts && product?.cuts?.length > 0 && (
                <FormControl>
                  <InputLabel id="cut-selection">Corte</InputLabel>
                  <Select
                    style={{ width: 150 }}
                    labelId="cut-selection"
                    onChange={handleCutSelection}
                  >
                    {product?.cuts &&
                      product?.cuts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <TextField
                label="Quantidade"
                type="number"
                inputProps={{ inputProps: { min: 0, step: 0.5 } }}
                onChange={(val) => {
                  setQuantity(parseFloat(val.target.value));
                }}
              />
              <Button
                onClick={() => {
                  if (quantity > 0 && product)
                    onClose({
                      product: { ...product },
                      cut,
                      quantity,
                      id: uuid(),
                    });
                  setCut(undefined);
                }}
              >
                Salvar
              </Button>
            </SelectProduct>
          </Collapse>
        </List>
        <Button
          onClick={() => {
            onClose('cancel');
          }}
        >
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PromoCart;
