import React, { useMemo } from 'react';
import { Container } from './styles';

interface IProps {
  value: number;
  title: string;
}

const NumberCard: React.FC<IProps> = (props) => {
  const { value, title } = props;
  const formatedValue = useMemo(() => {
    if (value > 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    if (value > 1e6) {
      return `${(value / 1000).toFixed(1)}M`;
    }
    return value.toFixed(0);
  }, [value]);
  return (
    <Container value={value} size={70}>
      <div className="box">
        <div className="number">
          <h2>{formatedValue}</h2>
        </div>
        <h2 className="text">{title}</h2>
      </div>
    </Container>
  );
};

export default NumberCard;
